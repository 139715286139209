import React, {Component} from "react"
import Cookies from 'universal-cookie'

import Layout from "components/Layout/LayoutUSTour"

import LandingBlock from "components/new-york/HomePage/LandingBlock"
import Winner from "components/new-york/HomePage/Winner"
import FluxBlock from "components/us-tour/FluxBlock"
import AudioBlock from "components/new-york/HomePage/AudioBlock";
import GreatScottBlock from "components/us-tour/GreatScottBlock"
import TourDates from "components/us-tour/TourDates";
import CastBlock from "components/us-tour/CastAndCreativePage/CastBlock"

const cookies = new Cookies();
const COOKIE_DOMAIN = "backtothefuturemusical.com";
let expiry = new Date();
expiry = new Date(expiry.setMonth(expiry.getMonth() + 3))

class IndexPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            audience: 'other',
            musicStopped: false,
            videoStopped: false,
            modalOpen: true,
        }

        this.stopMusic = this.stopMusic.bind(this);
        this.stopVideo = this.stopVideo.bind(this);


    }


    componentDidMount() {
        if (!cookies.get('awardsModal')) {
            this.setState({
                modalOpen: false,
            });

        }
    }

    stopMusic = (musicStopped) => {
        this.setState({
            musicStopped
        })
    }

    stopVideo = (videoStopped) => {
        this.setState({
            videoStopped
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.musicStopped !== this.state.musicStopped) {
            this.setState({
                musicStopped: this.state.musicStopped
            })
        }
    }

    toggleModal = (e) => {
        e.preventDefault();
        cookies.set('awardsModal', true, {
            expires: expiry,
            domain: COOKIE_DOMAIN
        });
        this.setState({
            modalOpen: !this.state.modalOpen
        });
    };

    render() {
        return (
            <Layout title="Back to the Future The Musical | North American Tour"
                    booking="https://www.telecharge.com/Broadway/Back-to-the-Future-the-Musical/Overview?AID=BWY001390400&utm_source=show_site&utm_campaign=Back-to-the-Future-the-MusicalSS&utm_medium=web"
                    description="The official website for Back to the Future The Musical - North American Tour. Landing in a city near you from Summer 2024. "
            >
                <LandingBlock/>
                <Winner/>
                {/* <ReviewSlider/> */}
                <FluxBlock
                    booking="https://www.telecharge.com/Broadway/Back-to-the-Future-the-Musical/Overview?AID=BWY001390400&utm_source=show_site&utm_campaign=Back-to-the-Future-the-MusicalSS&utm_medium=web"/>
                <TourDates />
                <AudioBlock
                    stopMusic={this.stopMusic}
                    stopVideo={this.stopVideo}
                    videoStopped={this.state.videoStopped}
                    musicStopped={this.state.musicStopped}
                    title="HEAR SOME HEAVY TUNES FROM THE SHOW"
                />
                <GreatScottBlock
                    stopMusic={this.stopMusic}
                    stopVideo={this.stopVideo}
                    videoId="YNSWbfNU8QU"
                    videoStopped={this.state.videoStopped}
                    musicStopped={this.state.musicStopped}
                    booking="https://www.telecharge.com/Broadway/Back-to-the-Future-the-Musical/Overview?AID=BWY001390400&utm_source=show_site&utm_campaign=Back-to-the-Future-the-MusicalSS&utm_medium=web"/>
            </Layout>
        )
    }
}

export default IndexPage
